import { selectOptionsAdapter } from '@intellecteu/common-ui';
import { capitalize } from 'app/utils';
import { MemoryEnum, StorageDto } from 'data';

export const memory = selectOptionsAdapter(Object.values(MemoryEnum));

export const storageOptions = Object.entries(StorageDto.type).map(([label, value]) => ({
  label: label !== StorageDto.type.POSTGRES ? capitalize(label.toLowerCase()) : 'PostgreSQL',
  value,
}));
