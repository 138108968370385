import * as Yup from 'yup';
import { REQUIRED_FIELD_ERROR_MESSAGE } from '../constants';
import { StorageDto } from '../openapi';

export const nameRegExp = /^[a-z]([a-z0-9_.-]*[a-z0-9])?$/;
export const imageRegExp =
  /^(?<repository>[\w.\-_]+((?::\d+|)(?=\/[a-z0-9._-]+\/[a-z0-9._-]+))|)(?:\/|)(?<image>[a-z0-9.\-_]+(?:\/[a-z0-9.\-_]+|))(:(?<tag>[\w.\-_]{1,127})|)$/;
export const urlRegExp = /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
export const userRegExp = /^[a-zA-Z0-9_.-]+?$/;
export const validatorNameRegexp = /^[a-zA-Z0-9\s\\(\\)_.-]+$/;

export const getNameValidation = (entity: string) => {
  return Yup.string()
    .max(30, 'The name should contain less or equal then 30 characters')
    .matches(
      nameRegExp,
      `${entity} name is not valid. It must consist of lower case alphanumeric characters (a-z) or '-', or '.', and must start with an alphanumeric character`,
    );
};

export const StorageSchemaValidation = () =>
  Yup.object().shape({
    type: Yup.mixed<StorageDto.type>().oneOf(Object.values(StorageDto.type)).required(REQUIRED_FIELD_ERROR_MESSAGE),
    user: Yup.string()
      .matches(userRegExp, 'User name is not valid')
      .test('User field should be not empty', REQUIRED_FIELD_ERROR_MESSAGE, function (value, context) {
        const type = (context.parent as StorageDto).type;
        if (type && type !== StorageDto.type.MEMORY && !value) return false;
        return true;
      }),
    password: Yup.string().test(
      'Password field should be not empty',
      REQUIRED_FIELD_ERROR_MESSAGE,
      function (value, context) {
        const type = (context.parent as StorageDto).type;
        if (type && type !== StorageDto.type.MEMORY && !value) return false;
        return true;
      },
    ),
    hostname: Yup.string()
      .matches(nameRegExp, 'Hostname value is not valid')
      .test('Hostname field should be not empty', REQUIRED_FIELD_ERROR_MESSAGE, function (value, context) {
        if ((context.parent as StorageDto).type === StorageDto.type.EXTERNAL && !value) return false;
        return true;
      }),
    dbName: Yup.string().test(
      'dbName field should be not empty',
      REQUIRED_FIELD_ERROR_MESSAGE,
      function (value, context) {
        if ((context.parent as StorageDto).type === StorageDto.type.EXTERNAL && !value) return false;
        return true;
      },
    ),
    // during validation the size should be the next format and when we send it to
    // server change to string
    size: Yup.object().shape({
      value: Yup.number(),
      unit: Yup.string(),
    }),
  });

export const horizonServiceCreationSchema = Yup.object().shape({
  name: getNameValidation('Horizon').required(REQUIRED_FIELD_ERROR_MESSAGE),
  image: Yup.string()
    .max(30, 'The image should contain correct values')
    .matches(imageRegExp, 'Image value is not valid'),
  storage: StorageSchemaValidation(),
});
