/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type HomeDomainDto = {
  homeDomain: string;
  quality: HomeDomainDto.quality;
};
export namespace HomeDomainDto {
  export enum quality {
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
  }
}

