export * from './capitalize';
export * from './storage-updaters';
export * from './replace-empty-strings-with-null';
export * from './date-parsers';

export const handleIntegerInput = (e) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  if (/[0-9]|\./.test(keyValue)) return;
  e.preventDefault();
};

export const handleCpuResourcesInput = (e) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  if (/[0-9]|\.|m/.test(keyValue)) return;
  e.preventDefault();
};

export const unitSplitter = (obj?: { value: string; unit: string }): string => {
  if (!obj) return '';
  return `${obj.value}${obj.unit}`;
};
