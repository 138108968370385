import { BrowserRouter } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';
import { BreadcrumbsProvider, Loader } from '@intellecteu/common-ui';
import { Toast } from 'app/components/core';
import { useStore } from 'data';
import App from './pages';
import keycloak from '../data/keycloak';

import 'app/styles/global.module.scss';

const container = document.getElementById('root') as HTMLElement;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const Stellar = () => {
  const { setAuthorization } = useStore();

  const eventLogger = (event: unknown) => {
    if (event && event === 'onAuthSuccess') {
      setAuthorization(keycloak);
    }
  };

  return (
    <ReactKeycloakProvider
      initOptions={{ onLoad: 'login-required' }}
      LoadingComponent={<Loader.FullPage />}
      authClient={keycloak}
      onEvent={eventLogger}
    >
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <BreadcrumbsProvider>
            <>
              <App />
              <Toast />
            </>
          </BreadcrumbsProvider>
        </BrowserRouter>
        {import.meta.env.VITE_MODE === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </ReactKeycloakProvider>
  );
};

const root = createRoot(container);

root.render(<Stellar />);
