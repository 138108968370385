/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StorageDto } from './StorageDto';

export type HorizonNodeDto = {
  image?: string;
  name: string;
  phase?: HorizonNodeDto.phase;
  network: HorizonNodeDto.network;
  storage: StorageDto;
  address?: string;
};
export namespace HorizonNodeDto {
  export enum phase {
    COMPLETED = 'COMPLETED',
    PENDING = 'PENDING',
    RUNNING = 'RUNNING',
    FAILED = 'FAILED',
  }

  export enum network {
    TESTNET = 'TESTNET',
    PUBNET = 'PUBNET',
  }
}
