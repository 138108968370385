import { Status, type TStatusMode, selectOptionsAdapter } from '@intellecteu/common-ui';
import { HorizonNodeDto, MemoryEnum, HomeDomainDto, StellarNodeDto } from 'data';

// STATUS
type TStatusConfigKey = HorizonNodeDto.phase | StellarNodeDto.phase;

type TStatusConfig = {
  [key in TStatusConfigKey]?: {
    mode: TStatusMode;
    label: string;
  };
};

export const nodeStatusConfig: TStatusConfig = {
  [HorizonNodeDto.phase.PENDING]: {
    mode: Status.mode.processing,
    label: 'Initializing',
  },
  [HorizonNodeDto.phase.RUNNING]: {
    mode: Status.mode.success,
    label: 'Running',
  },
  [HorizonNodeDto.phase.FAILED]: {
    mode: Status.mode.error,
    label: 'Init failed',
  },
};

// DEFAULT
export const sizeDefault = '1';
export const stellarNodeImageDefault = 'stellar/stellar-core:21';
export const horizonNodeImageDefault = 'stellar/stellar-horizon:2.32.0';
export const DOMAIN = import.meta.env.VITE_REACT_APP_DOMAIN ?? 'stellar-dev.testing.catalyst.intellecteu.io';

// TYPES
export type UnitAdapter = {
  value: string;
  unit: MemoryEnum | keyof typeof MemoryEnum;
};

// LISTS/OPTIONS
export const nodeTypeOptions = selectOptionsAdapter(Object.values(StellarNodeDto.nodeType));

export const initialValidators = [{ address: '', history: '', homeDomain: '', name: '', publicKey: '' }];

export const qualityOptions = selectOptionsAdapter(Object.values(HomeDomainDto.quality));
