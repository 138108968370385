/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type StorageDto = {
  hostname?: string;
  password?: string;
  dbName?: string;
  size?: string;
  type: StorageDto.type;
  user?: string;
};
export namespace StorageDto {
  export enum type {
    MEMORY = 'Memory',
    POSTGRES = 'Postgres',
    EXTERNAL = 'External',
  }
}
