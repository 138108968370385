export enum ErrorType {
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  SERVER_ERROR = 'SERVER_ERROR',
  FORBIDDEN = 'FORBIDDEN',
  UNAVAILABLE = 'UNAVAILABLE',
  CLIENT_RESPONSIBLE = 'CLIENT_RESPONSIBLE',
  VALIDATION = 'VALIDATION',
  UNKNOWN = 'UNKNOWN',
  IGNORED = 'IGNORED',
}

export enum QueryKeys {
  HORIZON_NODES = 'HORIZON_NODES',
  STELLAR_NODES = 'STELLAR_NODES',
  COMMAND = 'COMMAND',
}

export enum MemoryEnum {
  'Ei' = 'Ei',
  'Pi' = 'Pi',
  'Ti' = 'Ti',
  'Gi' = 'Gi',
  'Mi' = 'Mi',
  'Ki' = 'Ki',
}

export enum LevelType {
  'debug' = 'debug',
  'info' = 'info',
  'notice' = 'notice',
  'warn' = 'warn',
  'error' = 'error',
}

export * from './dictionary';
