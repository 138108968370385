import { memo } from 'react';
import cx from 'classnames';
import { SelectInput, TextInput, Text, selectValueAdapter } from '@intellecteu/common-ui';
import { handleIntegerInput } from 'app/utils/helpers';
import { storageOptions, memory } from 'app/constants';
import { StorageDto } from 'data';
import { Field } from '../ui/form/field';

import s from './styles.module.scss';

type StorageProps = {
  type?: StorageDto.type;
  className?: string;
  wrapperClassName?: string;
  options?: { label: string; value: StorageDto.type }[];
};

// TODO: compare with StorageFormFields
const StorageComponent = ({ type, className, wrapperClassName, options }: StorageProps) => (
  <div className={cx(s.wrapper, wrapperClassName)}>
    <Text component="p" weight="bold" size="s" className={s.containerFormSubtitle}>
      Storage
    </Text>

    <Field
      className={s.containerFormField}
      component={SelectInput}
      id="storage.type"
      name="storage.type"
      label="Type*"
      options={options ?? storageOptions}
      onChangeAdapter={(v) => v?.value}
      valueAdapter={selectValueAdapter(storageOptions)}
      placeholder="Select storage type..."
    />

    <div className={cx(s.formSidebarSection, className)}>
      {type && type !== StorageDto.type.MEMORY && (
        <>
          <Field
            className={s.containerFormField}
            component={TextInput}
            id="storage.user"
            name="storage.user"
            label="User*"
            data-1p-ignore
          />
          <Field
            className={s.containerFormField}
            component={TextInput}
            id="storage.password"
            name="storage.password"
            label="Password*"
            type="password"
            data-1p-ignore
          />
          {type === StorageDto.type.EXTERNAL && (
            <>
              <Field
                className={s.containerFormField}
                component={TextInput}
                id="storage.hostname"
                name="storage.hostname"
                label="Hostname*"
                data-1p-ignore
              />
              <Field
                className={s.containerFormField}
                component={TextInput}
                id="storage.dbName"
                name="storage.dbName"
                label="Dbname*"
                data-1p-ignore
              />
            </>
          )}
          {type === StorageDto.type.POSTGRES && (
            <div className={cx(s.containerFormField, s.fieldGroup)}>
              <Field
                className={s.fieldGroupMain}
                component={TextInput}
                id="storage.size.value"
                name="storage.size.value"
                label="Storage size"
                onKeyPress={handleIntegerInput}
                data-1p-ignore
              />
              <Field
                className={s.fieldGroupSelect}
                options={memory}
                component={SelectInput}
                id="storage.size.unit"
                name="storage.size.unit"
                onChangeAdapter={(v) => v?.value}
                valueAdapter={(v) => {
                  return memory?.find((el) => el.value === v) || '';
                }}
                maxMenuHeight="200px"
                isMenuAbove
              />
            </div>
          )}
        </>
      )}
    </div>
  </div>
);

export const Storage = memo(StorageComponent);
