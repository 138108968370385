/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HomeDomainDto } from './HomeDomainDto';
import type { StorageDto } from './StorageDto';
import type { ValidatorDto } from './ValidatorDto';

export type StellarNodeDto = {
  image?: string;
  name: string;
  network: StellarNodeDto.network;
  nodePublicKey: string;
  nodeSeed: string;
  nodeType: StellarNodeDto.nodeType;
  nodeAddress?: string;
  nodeHomeDomain?: string;
  historyAddress?: string;
  phase?: StellarNodeDto.phase;
  storage: StorageDto;
  homeDomains: Array<HomeDomainDto>;
  validators: Array<ValidatorDto>;
};
export namespace StellarNodeDto {
  export enum network {
    TESTNET = 'TESTNET',
    PUBNET = 'PUBNET',
  }

  export enum nodeType {
    WATCHER = 'Watcher',
    VALIDATOR = 'Validator',
    FULL_VALIDATOR = 'Full Validator',
  }

  export enum phase {
    COMPLETED = 'COMPLETED',
    PENDING = 'PENDING',
    RUNNING = 'RUNNING',
    FAILED = 'FAILED',
  }
}
